import React from 'react'
import { graphql } from 'gatsby'
import { Location } from '@reach/router'
import qs from 'qs'

import PageHeader from '../components/PageHeader'
import PostSection from '../components/PostSection'
import Layout from '../components/Layout'
import MyContext from '../components/Context'

/**
 * Filter posts by date. Feature dates will be fitered
 * When used, make sure you run a cronejob each day to show schaduled content. See docs
 *
 * @param {posts} object
 */
export const byDate = posts => {
  const now = Date.now()
  return posts.filter(post => Date.parse(post.date) <= now)
}

/**
 * filter posts by category.
 *
 * @param {posts} object
 * @param {title} string
 * @param {contentType} string
 */
export const byCategory = (posts, title, contentType) => {
  const isCategory = contentType === 'postCategories'
  const byCategory = post => post.categories && post.categories == title
  return isCategory ? posts.filter(byCategory) : posts
}

// Export Template for use in CMS preview
export const BlogIndexTemplate = ({
  title,
  title_en,
  subtitle_ar,
  title_ar,
  subtitle_en,
  featuredImage,
  posts = [],
  enableSearch = true,
  contentType
}) => {
  return (
    <MyContext.Consumer>
      {cons => (
        <Location>
          {({ location }) => {
            let filteredPosts =
              posts && !!posts.length
                ? byCategory(posts, title, contentType)
                : []

            let queryObj = location.search.replace('?', '')
            queryObj = qs.parse(queryObj)

            if (enableSearch && queryObj.s) {
              const searchTerm = queryObj.s.toLowerCase()
              filteredPosts = filteredPosts.filter(post =>
                post.frontmatter.title_en.toLowerCase().includes(searchTerm)
              )
            }

            return (
              <main className="Blog">
                {/* {!!postCategories.length && (
                <section className="section thin">
                  <div className="container">
                    <PostCategoriesNav
                      enableSearch
                      categories={postCategories}
                    />
                  </div>
                </section>
              )} */}

                {!!posts.length && (
                  <section>
                    <div style={{ marginBottom: '20px' }}>
                      <PageHeader
                        title={cons.i18n.language == 'en' ? title_en : title_ar}
                        subtitle={
                          cons.i18n.language == 'en' ? subtitle_en : subtitle_ar
                        }
                        backgroundImage={featuredImage}
                      />
                    </div>
                    <PostSection posts={filteredPosts} />
                  </section>
                )}
              </main>
            )
          }}
        </Location>
      )}
    </MyContext.Consumer>
  )
}

// Export Default BlogIndex for front-end
const BlogIndex = ({ data: { page, posts, postCategories } }) => {
  return (
    <Layout
      meta={page.frontmatter.meta || false}
      title={page.frontmatter.title || false}
      noAnalytics={true}
    >
      <BlogIndexTemplate
        {...page}
        {...page.fields}
        {...page.frontmatter}
        posts={posts.edges.map(post => ({
          ...post.node,
          ...post.node.frontmatter,
          ...post.node.fields
        }))}
        postCategories={postCategories.edges.map(post => ({
          ...post.node,
          ...post.node.frontmatter,
          ...post.node.fields
        }))}
      />
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  ## Query for BlogIndex data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query BlogIndex($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      fields {
        contentType
      }
      frontmatter {
        title
        title_en
        title_ar
        excerpt
        template
        subtitle_ar
        subtitle_en
        featuredImage
      }
    }

    posts: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "posts" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            lang
            title
            date
            categories
            featuredImage
            excerpt
          }
        }
      }
    }

    postCategories: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "postCategories" } } }
      sort: { order: ASC, fields: [frontmatter___title] }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title_en
            title
          }
        }
      }
    }
  }
`
