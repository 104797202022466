import React from 'react'
import { Link } from 'gatsby'

import Image from './Image'
import './PostCard.css'
import MyContext from './Context'

const PostCard = ({
  featuredImage,
  title,
  excerpt,
  date,
  slug,
  categories,
  className = '',
  ...props
}) => {
  return (
    <MyContext.Consumer>
      {cons => (
        <Link to={slug} className={`PostCard ${className}`}>
          {featuredImage && (
            <div className="PostCard--Image relative">
              <Image background src={featuredImage} alt={title} />
            </div>
          )}
          <div
            className="PostCard--Content"
            style={{ paddingTop: !featuredImage ? '2.5rem' : null }}
          >
            {title && <h3 className="PostCard--Title">{title}</h3>}
            <div className="PostCard--Category">
              {categories && cons.strings(categories)}
            </div>

            {date && (
              <div style={{ marginBottom: '20px' }}>
                <time
                  className="SinglePost--Meta--Date"
                  itemProp="dateCreated pubdate datePublished"
                  date={date}
                >
                  {date.substring(0, date.length - 14)}
                </time>
              </div>
            )}
            {excerpt && (
              <div className="PostCard--Excerpt">{`${excerpt}...`}</div>
            )}
          </div>
        </Link>
      )}
    </MyContext.Consumer>
  )
}

export default PostCard
